
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons/faMobileAlt';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { useFontAwesomeIconComponent } from 'buetify/lib/components/icons/utils';
import BMenu from 'buetify/lib/components/menu/BMenu';
import BMenuGroup from 'buetify/lib/components/menu/BMenuGroup';
import BMenuLabel from 'buetify/lib/components/menu/BMenuLabel';
import BMenuList from 'buetify/lib/components/menu/BMenuList';
import BMenuListItem from 'buetify/lib/components/menu/BMenuListItem';
import { defineComponent } from 'vue';

const UserIcon = useFontAwesomeIconComponent('User', faUser);
const PhoneIcon = useFontAwesomeIconComponent('Phone', faMobileAlt);
const PaymentsIcon = useFontAwesomeIconComponent('Payments', faCreditCard);

export default defineComponent({
	name: 'font-awesome-icons-example',
	components: {
		BMenu,
		BMenuGroup,
		BMenuListItem,
		BMenuLabel,
		BMenuList,
		UserIcon,
		PhoneIcon,
		PaymentsIcon
	}
});
